import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import "./Header.scss";

function Header() {
  const location = useLocation();
  const currentLocation = location.pathname;
  const navigate = useNavigate();

  return (
    <>
      <header className="header">
        <div className="title">
          <h1>
            <Link to="/">Kévin Durand</Link>
          </h1>
          <h3>Partage publique</h3>
        </div>

        <div className="page-navigation">
          <button
            type="button"
            className="arrow left"
            onClick={() => navigate(-1)}
          >
            <i className="icon-circle-arrow-left" />
          </button>
          <button
            type="button"
            className="arrow right"
            onClick={() => navigate(1)}
          >
            <i className="icon-circle-arrow-right" />
          </button>
        </div>
      </header>
      {currentLocation !== "/" && (
        <nav className="navigation">
          <i className="icon-folder-v2" />{" "}
          {parse(currentLocation.replace(/(\/)/gm, `<span> $1 </span>`))}
        </nav>
      )}
    </>
  );
}

export default Header;
