import movie1 from "./1 - Aborder les librairies.mp4";
import movie2 from "./2 - Découvrir postman.mp4";
import movie3 from "./3 - Installer Node.mp4";
import movie4 from "./4 - Installer MongoDB.mp4";
import movie5 from "./5 - Configurer Babel.mp4";
import movie6 from "./6 - Appréhender API RESTful.mp4";
import movie7 from "./7 - Procéder à la config du serveur initial.mp4";
import movie8 from "./8 - Structurer les fichiers et les dossiers.mp4";
import movie9 from "./9 - Etablir les routes initiale.mp4";
import movie10 from "./10 - Aborder les bases du middleware - Qu'est ce qu'un middleware.mp4";
import movie11 from "./11 - Découvrir Mongo.mp4";
import movie12 from "./12 - Configurer les BDD - Nous sommes maintenant pret.mp4";
import movie13 from "./13 - Configurer le schéma - Avant de commencer à ajouter des opérations.mp4";
import movie14 from "./14 - POST - Maintenant nous allons compléter notre point de terminaison.mp4";
import movie15 from "./15 - GET - Maintenant que nous avons un élément dans notre base de données.mp4";
import movie16 from "./16 - GET id - Que faire si vous devez récupérer uniquement les informations.mp4";
import movie17 from "./17 - PUT - Maintenant lorsque nous devons mettre à jour.mp4";
import movie18 from "./18 - DELETE - Le point de terminaison final que nous devons.mp4";
import movie19 from "./19 - Fichier statique - avec le serveur express il est possible.mp4";
import movie20 from "./20 - Autres librairies - Fond KOA.mp4";
import movie21 from "./21 - Conclusion - Fond conclusion.mp4";

export default [
  { source: movie1, name: "1 - Aborder les librairies" },
  { source: movie2, name: "2 - Découvrir postman" },
  { source: movie3, name: "3 - Installer Node" },
  { source: movie4, name: "4 - Installer MongoDB" },
  { source: movie5, name: "5 - Configurer Babel" },
  { source: movie6, name: "6 - Appréhender API RESTful" },
  { source: movie7, name: "7 - Procéder à la config du serveur initial" },
  { source: movie8, name: "8 - Structurer les fichiers et les dossiers" },
  { source: movie9, name: "9 - Etablir les routes initiale" },
  {
    source: movie10,
    name: "10 - Aborder les bases du middleware - Qu'est ce qu'un middleware",
  },
  { source: movie11, name: "11 - Découvrir Mongo" },
  {
    source: movie12,
    name: "12 - Configurer les BDD - Nous sommes maintenant pret",
  },
  {
    source: movie13,
    name: "13 - Configurer le schéma - Avant de commencer à ajouter des opérations",
  },
  {
    source: movie14,
    name: "14 - POST - Maintenant nous allons compléter notre point de terminaison",
  },
  {
    source: movie15,
    name: "15 - GET - Maintenant que nous avons un élément dans notre base de données",
  },
  {
    source: movie16,
    name: "16 - GET id - Que faire si vous devez récupérer uniquement les informations",
  },
  {
    source: movie17,
    name: "17 - PUT - Maintenant lorsque nous devons mettre à jour",
  },
  {
    source: movie18,
    name: "18 - DELETE - Le point de terminaison final que nous devons",
  },
  {
    source: movie19,
    name: "19 - Fichier statique - avec le serveur express il est possible",
  },
  { source: movie20, name: "20 - Autres librairies - Fond KOA" },
  { source: movie21, name: "21 - Conclusion - Fond conclusion" },
];
