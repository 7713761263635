import React from "react";
import { Routes, Route } from "react-router-dom";

import Header from "./components";
import {
  Home,
  Page404,
  LinkedInExpress,
  LinkedInExpressChapter,
} from "./pages";

import "assets/fonts/custom/custom.css";

import "./App.scss";

function App() {
  return (
    <>
      <Header />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/linkedin-express" element={<LinkedInExpress />} />
        <Route
          exact
          path="/linkedin-express/:chapter"
          element={<LinkedInExpressChapter />}
        />
        <Route exact path="*" element={<Page404 />} />
      </Routes>
    </>
  );
}

export default App;
