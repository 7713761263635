import React from "react";
import { useParams } from "react-router-dom";

import Movies from "assets/movies/linkedin-express";

import "./LinkedInExpressChapter.scss";

function LinkedInExpressChapter() {
  const { chapter } = useParams();

  document.title = `Chapitre ${chapter.replace(
    "chapter-",
    ""
  )} | LinkedIn - Express leçons`;

  return (
    <main className="video-page">
      <video controls>
        <source
          src={Movies[Number(chapter.replace("chapter-", "")) - 1].source}
          type="video/mp4"
        />
        <track kind="captions" />
      </video>
    </main>
  );
}

export default LinkedInExpressChapter;
