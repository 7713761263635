import React from "react";
import { Link } from "react-router-dom";

import Movies from "assets/movies/linkedin-express";

import "./LinkedInExpress.scss";

function LinkedInExpress() {
  document.title = "LinkedIn - Express leçons";

  return (
    <main className="page">
      <div className="movies-list">
        <div className="first-col">
          {Movies.filter((movie, index) => {
            if (index + 1 <= Math.ceil(Movies.length / 2)) {
              return true;
            }
            return false;
          }).map((movie, index) => (
            <Link
              to={`/linkedin-express/chapter-${index + 1}`}
              key={movie.name}
            >
              <div className="movie">{movie.name}</div>
            </Link>
          ))}
        </div>
        <div className="second-col">
          {Movies.filter((movie, index) => {
            if (index + 1 > Math.ceil(Movies.length / 2)) {
              return true;
            }
            return false;
          }).map((movie, index) => (
            <Link
              to={`/linkedin-express/chapter-${
                index + 1 + Math.ceil(Movies.length / 2)
              }`}
              key={movie.name}
            >
              <div className="movie">{movie.name}</div>
            </Link>
          ))}
        </div>
      </div>
    </main>
  );
}

export default LinkedInExpress;
