import React from "react";
import { Link } from "react-router-dom";

import "./Home.scss";

function Home() {
  document.title = "Simple Sharing service";

  return (
    <main className="home">
      <section className="linkedin-express">
        <Link to="/linkedin-express">
          <div className="btn">LinkedIn: Express en 21 vidéos</div>
        </Link>
      </section>
    </main>
  );
}

export default Home;
